/* You can add global styles to this file, and also import other style files */
@import "styles/vendor";
@import "styles/mixin";
@import "styles/variables";
@import "styles/typography";
@import "styles/buttons";
@import "styles/form";
@import "styles/toast";
@import "styles/media";
@import "styles/calender";
@import "styles/global";
@import "styles/spinner";
