:root {
  //Colors of application
  --app-primary-color: #0033A0;
  --app-danger-color: #ff0000;
  --app-warning-color: #f39c12;
  --app-success-color: green;
  --app-info-color: #0066cc;
  --app-black-color: #000;
  --app-default-color: #333;
  --app-text-color: #8a92a0;
  --app-heading-color: var(--app-default-color);
  --app-text-light: #d4d4d4;
  --app-gray-color: #bebebe;
  --app-gray-2: #989898;
  --app-gray-dark: #5d5d5d;
  --app-light-blue: #6baeee;
  --app-hover-blue: #eaf7ff;
  --pm-primary: var(--app-primary-color);
  --mat-stepper-container-text-font: "Poppins",sans-serif;

  //font variable
  --app-font-primary: 'Poppins', sans-serif;
  --app-line-height: 1.45;
  --app-heading-line-height: 1.1;

  //font-size
  --app-default-size: 16px;
  --app-h1-size: 3.1rem;
  --app-h2-size: 1.8rem;
  --app-h3-size: 1.5rem;
  --app-h4-size: 18px;
  --app-h5-size: 16px;
  --app-h6-size: 14px;

  //path variable
  $image-path: '../assets/images/';

  .staff {
    --app-primary-color: rgb(63,68,67);
    --app-primary-color-light: rgb(63,68,67);
  }

  .donor {
    --app-primary-color: rgb(255,164,0);
    --app-primary-color-light: rgb(255,164,0);

  }

  --app-primary-color-light: rgb(0,50,160);
  //this will be changed accordingly
  --alert-default-height: 43px;

}

$primary: var(--app-primary-color);
