.form-header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.form {
  .mb-3 {
    margin-bottom: 1.35rem;
  }
}

.form-control {
  font-size: 14px;
  padding: 5px 15px;
  border-radius: 4px;
  height: 37px;
  color: var(--app-heading-color);
  font-weight: 500;
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: var(--app-text-color) !important;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: var(--app-text-color) !important;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: var(--app-text-color) !important;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: var(--app-text-color) !important;
  }
  &:focus {
    box-shadow: 0 0 0 1.5px rgba(0, 123, 255, 0.15);
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.angular-editor-textarea {
  min-height: 140px !important;
  resize: none !important;
  font-size: 14px;
  font-weight: 500;
  &:after {
    content: none !important;
  }
}

.custom-select {
  background-image: none !important;
  background-color: #fff;
}

.customselect-wrap {
  position: relative;
  height: 37px;
  &:before {
    position: absolute;
    content: '▲';
    top: 1px;
    bottom: 1px;
    width: 23px;
    right: 10px;
    color: var(--app-default-color);
    pointer-events: none;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-180deg);
    background: #fff;
  }
  select {
    height: 100%;
    border-radius: 4px;
  }
  &.disabled {
    &:before{
      opacity: 0.4;
      background: transparent;
    }

  }
}

.dropdown-select {
  position: relative;
  &:before {
    position: absolute;
    content: 'arrow_drop_down';
    font-family: 'Material Icons';
    top: 0;
    bottom: 0;
    width: 20px;
    right: 10px;
    pointer-events: none;
    font-size: 28px;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  select {
    min-width: 88px;
    background: #fff;
    padding: 2px 15px;
  }
}

.search-input {
  &:after {
    font-family: 'Material Icons';
    content: 'search';
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 20px;
    height: 100%;
    display: flex;
    align-items: flex-start;
  }
}

textarea {
  resize: none !important;
  height: 120px !important;
}

.mat-mdc-form-field-label  {
  transform: none;
}
//customized fontsize as per BS-4
.invalid-feedback {
  font-size: 12.8px ;
  line-height: 18.56px;
  letter-spacing: normal ;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  box-shadow: 0 0 0 0.1rem rgba(220, 53, 69, 0.25);
}

// input.mat-mdc-input-element {
//     height: 16px;
// }

mat-google-maps-autocomplete {
  input.mat-mdc-input-element {
    height: 50px;
    padding: 0 5px;
  }
}

.custom-field,
.filter-area {

  .mat-mdc-form-field-infix,
  .mat-mdc-form-field-flex,
  .mat-mdc-form-field-label-wrapper ,
  .mat-mdc-form-field-infix .search{
    height: 100%;
  }

  .mat-mdc-form-field-label {
    transform: none !important;
    width: auto !important;
  }

  input.mat-mdc-input-element {
    padding-right: 15px;
    &:focus {
      box-shadow: none;
    }
  }

  .mat-mdc-text-field-wrapper {
    background: #fff;
    padding: 0 10px 0 10px;
    border-radius: 4px;
    height: 37px;
    border: 1px solid #ced4da;
    position: relative;
  }

  .mat-mdc-select {
    border: 0 !important;
  }

  .mat-mdc-form-field-infix ,
  .mat-mdc-form-field-label-wrapper,
  .mat-mdc-form-field-infix .search {
    padding: 0;
    border: 0;
    display: flex;
    align-items: center;
  }

  .mat-mdc-form-field-label-wrapper {
    top: 0;
  }

  .mat-mdc-form-field-flex {
    align-items: center;
  }

  .mat-mdc-form-field-underline  {
    display: none;
  }

  app-auto-complete  .view {
    background: #fff;
    font-size: 14px !important;
    padding: 5px 15px;
    border-radius: 4px;
    height: 37px;
    color: var(--app-heading-color);
    // box-shadow: 0 5px 13px 0 var(--app-text-light);
    font-weight: 500;
    border: 1px solid #ced4da;
    margin-bottom: 1rem;
  }

  .mat-mdc-input-element {
    border: 0 !important;
    padding: 0 !important;
  }


  input,
  .mat-mdc-form-field{
    font-size: 14px !important;
    font-weight: 500;
  }

  .mat-mdc-form-field-label {
    display: none !important;
    top: 50% !important;
    font-size: 14px !important;
    font-weight: 500;
    color: var(--app-text-color);
    -webkit-font-smoothing: antialiased;
    margin-bottom: 0;
    position: static;
    &.mat-mdc-form-field-empty {
      display: block !important;
    }
  }

}



.mb-3 {
  .view {
    border-bottom: 1px solid #ced4da;
  }
  .mat-mdc-form-field-underline {
    background: #ced4da;
  }
}

.ae-font .ae-picker-label:before {
  height: 24px !important;
}

.dropdown-field {
  .mat-mdc-form-field-wrapper {
    &:before {
      position: absolute;
      content: "arrow_drop_down";
      font-family: "Material Icons";
      top: 0;
      bottom: 0;
      width: 20px;
      right: 0;
      pointer-events: none;
      font-size: 28px;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }
}

/*== common form ====*/

label {
  -webkit-font-smoothing: initial;
}

.form-card {
  max-width: 950px;
  padding: 2.2rem 2.6rem;
  margin-left: 8%;
  overflow-x: hidden;
  .form {
    max-width: 700px;
  }
  .mat-mdc-tab-body-wrapper {
    overflow: initial;
  }
}

/*==== mat fields =====*/

.mat-mdc-ripple-element {
  display: none;
}

.mat-mdc-radio-button {
  &.mat-accent .mat-mdc-radio-inner-circle{
    background-color: var(--app-info-color);
  }

  &.mat-accent.mat-mdc-radio-checked {
    .mdc-radio__outer-circle, .mdc-radio__inner-circle {
      border-color: var(--app-info-color) !important;
    }
  }
  .mat-mdc-radio-container {
    transform: scale(0.8);
    margin-top: 3px;
  }

  .mat-mdc-radio-label-content {
    label {
      margin: 0;
    }
  }
  .mdc-radio {
    padding: 0 !important;
  }
  >.mdc-form-field {
    align-items: flex-start;
  }
}

.mat-mdc-form-field-type-mat-select {
  .mat-mdc-select {
    font-family: var(--app-font-primary);
    border: 1px solid #ced4da;
    height: 37px;
    border-radius: 4px;
    padding: 7px 15px;
    font-size: 14px !important;
  }
  .mat-mdc-form-field-label {
    span{
      display: none;
    }
  }

  .mat-mdc-select-value .mat-mdc-select-placeholder {
    color: var(--app-text-color);
    font-weight: 500;
    -webkit-text-fill-color: initial;
  }
}

.mat-mdc-form-field-invalid {
  .mat-mdc-input-element,
  .mat-mdc-select {
    border-color: var(--app-danger-color);
  }
}

.mat-mdc-form-field.mat-mdc-focused {
  .mat-mdc-form-field-label {
    color: var(--app-text-color);
  }
}


.mat-mdc-primary .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-disabled) {
  color: var(--app-default-color);
}

.mat-mdc-slide-toggle.mat-mdc-primary.mat-checked {
  .mat-mdc-slide-toggle-thumb {
    background-color: var(--app-info-color);
  }
  .mat-mdc-slide-toggle-bar {
    background-color: ar(--app-light-color)
  }
}
/*==== mat tabs ====*/

.mat-mdc-tab-group {
  font-family: inherit;
}

.mat-mdc-tab-body {
  width: 100%;
}

.mat-mdc-tab-labels, .mdc-tab__text-label  {
  font-size: 1.2rem;
  font-family: var(--app-font-primary);
  letter-spacing: initial;
}

.mat-mdc-tab {
  flex-grow: 0 !important;
}

.mat-mdc-tab-body-wrapper {
  .mat-mdc-tab-body.mat-tab-body-active,
  .mat-mdc-tab-body-content {
    overflow: initial;
  }
}
.mdc-tab__ripple{
  display: none;
}

.mat-mdc-tab-header-pagination {
  box-shadow: none;
  padding: 0 !important;
  min-width: 17px !important;
}

/*=== mat stepper =====*/

/*disable mat-stepper header navigation ==*/
.mat-mdc-horizontal-stepper-header {
  pointer-events: none !important;
  padding: 0 !important;
}

.mat-mdc-stepper-horizontal {
  font-family: inherit !important;
  .mat-horizontal-content-container {
    padding: 2rem 0;
  }
  // .mat-stepper-content {
  //     padding: 0 15px;
  // }
}

.mat-stepper-header-container {
  justify-content: space-between;
  position: relative;
  &:before {
    position: absolute;
    content: '';
    left: 0;
    background: var(--app-gray-color);
    width: 100%;
    height: 1px;
  }
  .mat-stepper-horizontal-line {
    display: none;
  }
  .mat-step-label  {
    display: none;
  }
}

.mat-stepper-header {
  padding: 0 !important;
  .mat-step-icon {
    margin-right: 0 !important;
  }
}
// .mat-mdc-text-field-wrapper.mdc-text-field {
//     padding: 0;
// }


.mat-mdc-option {
  line-height: 42px;
  // height: 42px;
  > * {
    word-break: break-word;
    overflow-wrap: break-word;
  }
  .mat-mdc-option-text {
    img {
      width: 25px;
      height: 25px;
    }
  }
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  button {
    color: #424242;
    transform: rotate(-44deg);
    font-size: 22px;
    outline: none !important;
  }

  input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
  }

}




@media (max-width: 1499px) {
  .form-card {
    margin-left: 5%;
  }
}

@media (max-width: 1099px) {
  .form-card {
    margin-left: 0;
  }
}

@media (max-width: 991px) {
  .filter-area input,
  .filter-area .mat-form-field,
  .form-control,
  .mat-mdc-input-element,
  .custom-field input, .custom-field .mat-mdc-form-field{
    font-size: 16px !important;
  }
}


@media (max-width: 767px) {
  .mat-mdc-tab-label {
    padding: 0 10px !important;
  }
}

.mat-mdc-tooltip {
  font-size: 13px !important;
  background: var(--app-primary-color) !important;
}

.img-wrap {
  padding-top: 70%;
  box-shadow: inset 0 0 15px rgba(0,0,0,.1), inset 0 0 0 1px rgba(0,0,0,.05);
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.overlay {
  left: 0;
  bottom: 0;
  padding: 5px 15px;
  background: rgba(0,0,0,0.7);
}

.thumbnail {
  overflow: hidden;
  .overlay {
    text-align: center;
    transform: translateY(100%);
    transition: 0.3s ease;
  }

  &:hover {
    .overlay {
      transform: translate(0);
    }
  }
}

.online-form {

  select {
    padding-right: 40px !important;
    text-overflow: ellipsis;
  }

  span {
    font-size: 14px !important;
  }

  .mat-horizontal-content-container {
    padding: 2rem 0 0;
  }

  .mat-mdc-form-field-underline {
    display: none;
  }

  .mat-mdc-form-field-wrapper {
    padding-bottom: 3px;
  }

  .mat-mdc-ripple-element {
    background: transparent;
  }

  .mat-mdc-form-field-icon-suffix {
    position: absolute;
    right: 5px;
    top: 40px;
    font-size: 20px;
    .mat-datepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base{
      width: 1.3rem;
      height: 1.3rem;
    }
    svg {
      height: 1.3rem !important;
      width: 1.3rem !important;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
    border: 1px solid #ced4da;
  }

  .mat-mdc-input-element {
    border-radius: 4px;
    padding: 5px;
    &:focus {
      box-shadow: 0 0 0 1.5px rgba(0, 123, 255, 0.15);
    }
  }

  .mat-mdc-form-field{
    span {
      font-size: 14px !important;
    }
  }

  textarea {
    height: 80px !important;
  }

  .mat-mdc-label {
    font-size: 18px;
  }

  .mat-mdc-radio-group {
    display: block;
    margin-bottom: 1.5rem;
  }

  .mat-mdc-form-field{
    margin-bottom: 1rem;
  }

  .btn-primary:focus,
  .btn-primary:active:focus {
    outline: none;
    box-shadow: none !important;
    border: none;
  }
}
