.cal-week-view {
    height: 100%;
    .cal-time-events {
     max-height: calc(100% - 50px);
     overflow-y: auto;
     overflow-x: hidden;
     -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
       width: 3px;
       border-radius: 8px;
     }
   
     &::-webkit-scrollbar-track {
       -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
       border-radius: 8px;
     }
   
     &::-webkit-scrollbar-thumb {
       background-color: #d8d8d8;
       border-radius: 8px;
     }
    }
    .cal-header {
      outline: none !important;
      &.cal-today {
        background-color: var(--app-hover-blue);
       }
    }
    .cal-day-columns {
      height: 100%;
    }
    // .cal-time-events .cal-event-container {
    //   width: 100% !important;
    //   left: 0 !important;
    // }
}

.cal-event  {
    background-color: var(--app-primary-color-light) !important;
    color: #fff !important;
    font-size: 14px;
    font-weight: 700;
    text-transform: capitalize;
    padding: 5px 8px !important;
    border-color: #fff !important;
    display: flex;
    margin-left: -1px !important;
    outline: none !important;
    .cal-event-actions {
     display: none;
     flex-direction: column;
     opacity: 0;
     transform: translateX(15px);
     transition: 0.2s ease;
     a {
       color: var(--app-gray-color);
       &:hover {
         color: #fff;
       }
     }
    }
    .material-icons {
      font-size: 18px;
    }
    .cal-event-title {
      display: inline-block;
    }
    mwl-calendar-event-actions {
      order: 1;
      margin-left: auto;
    }
    &:hover {
      .cal-event-actions {
        opacity: 1;
        transform: translateX(0);
      }
    }
}

.cal-month-view {
  .cal-day-cell,
  .cal-cell-top {
    min-height: 60px !important;
    padding: 0 5px;
  }
} 


  .cal-month-view .cal-header {
    text-align: center;
    font-weight: bolder;
  }
  
  .cal-month-view .cal-header .cal-cell {
    padding: 5px 0;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
  }
  
  .cal-month-view .cal-days {
    border: 1px solid;
    border-bottom: 0;
  }
  
  .cal-month-view .cal-cell-top {
    min-height: 78px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .cal-month-view .cal-cell-row {
    display: -webkit-box;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
  }
  
  .cal-month-view .cal-cell {
    float: left;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  
  .cal-month-view .cal-day-cell {
    min-height: 100px;
  }
  
  @media all and (-ms-high-contrast: none) {
    .cal-month-view .cal-day-cell {
      display: block;
    }
  }
  
  .cal-month-view .cal-day-cell:not(:last-child) {
    border-right: 1px solid;
  }
  
  .cal-month-view .cal-days .cal-cell-row {
    border-bottom: 1px solid;
  }
  
  .cal-month-view .cal-day-badge {
    margin-top: 18px;
    margin-left: 10px;
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 10px;
  }
  
  .cal-month-view .cal-day-number {
    font-size: 1.2em;
    font-weight: 400;
    opacity: 0.5;
    margin-top: 15px;
    margin-right: 15px;
    float: right;
    margin-bottom: 10px;
  }
  
  .cal-month-view .cal-events {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    margin: 3px;
    line-height: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  
  .cal-month-view .cal-event {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin: 2px;
  }
  
  .cal-month-view .cal-day-cell.cal-in-month.cal-has-events {
    cursor: pointer;
  }
  
  .cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
    opacity: 0.1;
    cursor: default;
  }
  
  .cal-month-view .cal-day-cell.cal-today .cal-day-number {
    font-size: 1.9em;
    color: var(--app-primary-color);
    opacity: 1;
  }
  
  .cal-month-view .cal-open-day-events {
    padding: 15px;
  }
  
  .cal-month-view .cal-open-day-events .cal-event {
    position: relative;
    top: 2px;
  }
  
  .cal-month-view .cal-out-month .cal-day-badge,
  .cal-month-view .cal-out-month .cal-event {
    opacity: 0.3;
  }
  
  .cal-month-view .cal-draggable {
    cursor: move;
  }
  
  .cal-month-view .cal-drag-active * {
    pointer-events: none;
  }
  
  .cal-month-view .cal-event-title {
    cursor: pointer;
  }
  
  .cal-month-view .cal-event-title:hover {
    text-decoration: underline;
  }
  
  .cal-month-view {
    background-color: #fff;
  }
  
  .cal-month-view .cal-cell-row:hover {
    background-color: #fafafa;
  }
  
  .cal-month-view .cal-cell-row .cal-cell:hover,
  .cal-month-view .cal-cell.cal-has-events.cal-open {
    background-color: #ededed;
  }
  
  .cal-month-view .cal-days {
    border-color: #e1e1e1;
  }
  
  .cal-month-view .cal-day-cell:not(:last-child) {
    border-right-color: #e1e1e1;
  }
  
  .cal-month-view .cal-days .cal-cell-row {
    border-bottom-color: #e1e1e1;
  }
  
  .cal-month-view .cal-day-badge {
    background-color: #b94a48;
    color: #fff;
  }
  
  .cal-month-view .cal-event {
    background-color: #1e90ff;
    border-color: #d1e8ff;
    color: #fff;
  }
  
  .cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
    color: #8b0000;
  }
  

  .cal-month-view .cal-day-cell.cal-drag-over {
    background-color: #e0e0e0 !important;
  }
  
  .cal-month-view .cal-open-day-events {
    color: #fff;
    background-color: #555;
    -webkit-box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5);
  }
  
  
  .cal-week-view * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .cal-week-view .cal-day-headers {
    display: -webkit-box;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    padding-left: 70px;
    border: 1px solid;
  }
  
  .cal-week-view .cal-day-headers .cal-header {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
    padding: 5px;
  }
  
  .cal-week-view .cal-day-headers .cal-header:not(:last-child) {
    border-right: 1px solid;
  }
  
  .cal-week-view .cal-day-headers .cal-header:first-child {
    border-left: 1px solid;
  }
  
  .cal-week-view .cal-day-headers span {
    font-weight: 400;
    opacity: 0.5;
  }
  
  .cal-week-view .cal-day-column {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    border-left: solid 1px;
  }
  
  .cal-week-view .cal-event {
    font-size: 12px;
    border: 1px solid;
  }
  
  .cal-week-view .cal-time-label-column {
    width: 70px;
    height: 100%;
  }
  
  .cal-week-view .cal-current-time-marker {
    position: absolute;
    width: 100%;
    height: 2px;
    z-index: 2;
  }
  
  .cal-week-view .cal-all-day-events {
    border: solid 1px;
    border-top: 0;
    border-bottom-width: 3px;
    padding-top: 3px;
    position: relative;
  }
  
  .cal-week-view .cal-all-day-events .cal-day-columns {
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    position: absolute;
    top: 0;
    z-index: 0;
  }
  
  .cal-week-view .cal-all-day-events .cal-events-row {
    position: relative;
    height: 31px;
    margin-left: 70px;
  }
  
  .cal-week-view .cal-all-day-events .cal-event-container {
    display: inline-block;
    position: absolute;
  }
  
  .cal-week-view .cal-all-day-events .cal-event-container.resize-active {
    z-index: 1;
    pointer-events: none;
  }
  
  .cal-week-view .cal-all-day-events .cal-event {
    padding: 0 5px;
    margin-left: 2px;
    margin-right: 2px;
    height: 28px;
    line-height: 28px;
  }
  
  .cal-week-view .cal-all-day-events .cal-starts-within-week .cal-event {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  
  .cal-week-view .cal-all-day-events .cal-ends-within-week .cal-event {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  
  .cal-week-view .cal-all-day-events .cal-time-label-column {
    display: -webkit-box;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 14px;
  }
  
  .cal-week-view .cal-all-day-events .cal-resize-handle {
    width: 6px;
    height: 100%;
    cursor: col-resize;
    position: absolute;
    top: 0;
  }
  
  .cal-week-view .cal-all-day-events .cal-resize-handle.cal-resize-handle-after-end {
    right: 0;
  }
  
  .cal-week-view .cal-event,
  .cal-week-view .cal-header {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .cal-week-view .cal-drag-active {
    pointer-events: none;
    z-index: 1;
  }
  
  .cal-week-view .cal-drag-active * {
    pointer-events: none;
  }
  
  .cal-week-view .cal-time-events {
    position: relative;
    border: solid 1px;
    border-top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
  }
  
  .cal-week-view .cal-time-events .cal-day-columns {
    display: -webkit-box;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  
  .cal-week-view .cal-time-events .cal-day-column {
    position: relative;
  }
  
  .cal-week-view .cal-time-events .cal-events-container {
    position: relative;
  }
  
  .cal-week-view .cal-time-events .cal-event-container {
    position: absolute;
    z-index: 1;
    min-height: 50px;
  }
  
  .cal-week-view .cal-time-events .cal-event {
    width: 100%;
    height: calc(100% - 2px);
    margin: 1px;
    padding: 0 5px;
    line-height: 25px;
  }
  
  .cal-week-view .cal-time-events .cal-resize-handle {
    width: 100%;
    height: 4px;
    cursor: row-resize;
    position: absolute;
  }
  
  .cal-week-view .cal-time-events .cal-resize-handle.cal-resize-handle-after-end {
    bottom: 0;
  }
  
  .cal-week-view .cal-hour-segment {
    position: relative;
  }
  
  .cal-week-view .cal-hour-segment::after {
    content: '\00a0';
  }
  
  .cal-week-view .cal-event-container:not(.cal-draggable) {
    cursor: pointer;
  }
  
  .cal-week-view .cal-draggable {
    cursor: move;
  }
  
  .cal-week-view mwl-calendar-week-view-hour-segment,
  .cal-week-view .cal-hour-segment {
    display: block;
  }
  
  .cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
  .cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
    border-bottom: thin dashed;
  }
  
  .cal-week-view .cal-time {
    font-weight: bold;
    padding-top: 5px;
    width: 70px;
    text-align: center;
  }
  
  .cal-week-view .cal-hour-segment.cal-after-hour-start .cal-time {
    display: none;
  }
  
  .cal-week-view .cal-starts-within-day .cal-event {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  
  .cal-week-view .cal-ends-within-day .cal-event {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  
  .cal-week-view {
    background-color: #fff;
    border-top: solid 1px #e1e1e1;
  }
  
  .cal-week-view .cal-day-headers {
    border-color: #e1e1e1;
    border-top: 0;
  }
  
  .cal-week-view .cal-day-headers .cal-header:not(:last-child) {
    border-right-color: #e1e1e1;
  }
  
  .cal-week-view .cal-day-headers .cal-header:first-child {
    border-left-color: #e1e1e1;
  }
  
  .cal-week-view .cal-day-headers .cal-header:hover,
  .cal-week-view .cal-day-headers .cal-drag-over {
    background-color: #ededed;
  }
  
  .cal-week-view .cal-day-column {
    border-left-color: #e1e1e1;
  }
  
  .cal-week-view .cal-event {
    background-color: #d1e8ff;
    border-color: #1e90ff;
    color: #1e90ff;
  }
  
  .cal-week-view .cal-all-day-events {
    border-color: #e1e1e1;
  }
  
  .cal-week-view .cal-header.cal-weekend span {
    color: #8b0000;
  }
  
  .cal-week-view .cal-time-events {
    border-color: #e1e1e1;
  }
  
  .cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover {
    background-color: #ededed;
  }
  
  .cal-week-view .cal-hour-odd {
    background-color: #fafafa;
  }
  
  .cal-week-view .cal-drag-over .cal-hour-segment {
    background-color: #ededed;
  }
  
  .cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
  .cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
    border-bottom-color: #e1e1e1;
  }
  
  .cal-week-view .cal-current-time-marker {
    background-color: #ea4334;
  }
  
  .cal-day-view {
    /* stylelint-disable-next-line selector-type-no-unknown */
  }
  
  .cal-day-view mwl-calendar-week-view-header {
    display: none;
  }
  
  .cal-day-view .cal-events-container {
    margin-left: 70px;
  }
  
  .cal-day-view .cal-day-column {
    border-left: 0;
  }
  
  .cal-day-view .cal-current-time-marker {
    margin-left: 70px;
    width: calc(100% - 70px);
  }
  
  .cal-tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.5;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    font-size: 11px;
    word-wrap: break-word;
    opacity: 0.9;
  }
  
  .cal-tooltip.cal-tooltip-top {
    padding: 5px 0;
    margin-top: -3px;
  }
  
  .cal-tooltip.cal-tooltip-top .cal-tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
  }
  
  .cal-tooltip.cal-tooltip-right {
    padding: 0 5px;
    margin-left: 3px;
  }
  
  .cal-tooltip.cal-tooltip-right .cal-tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
  }
  
  .cal-tooltip.cal-tooltip-bottom {
    padding: 5px 0;
    margin-top: 3px;
  }
  
  .cal-tooltip.cal-tooltip-bottom .cal-tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
  }
  
  .cal-tooltip.cal-tooltip-left {
    padding: 0 5px;
    margin-left: -3px;
  }
  
  .cal-tooltip.cal-tooltip-left .cal-tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
  }
  
  .cal-tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    text-align: center;
    border-radius: 0.25rem;
  }
  
  .cal-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  
  .cal-tooltip.cal-tooltip-top .cal-tooltip-arrow {
    border-top-color: #000;
  }
  
  .cal-tooltip.cal-tooltip-right .cal-tooltip-arrow {
    border-right-color: #000;
  }
  
  .cal-tooltip.cal-tooltip-bottom .cal-tooltip-arrow {
    border-bottom-color: #000;
  }
  
  .cal-tooltip.cal-tooltip-left .cal-tooltip-arrow {
    border-left-color: #000;
  }
  
  .cal-tooltip-inner {
    color: #fff;
    background-color: #000;
  }
  
  .cdk-global-overlay-wrapper, .cdk-overlay-container {
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
  }
  
  .cdk-overlay-container {
    position: fixed;
    z-index: 1000
  }
  
  .cdk-overlay-container:empty {
    display: none
  }
  
  .cdk-global-overlay-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    z-index: 1000
  }
  
  .cdk-overlay-pane {
    position: absolute;
    pointer-events: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    z-index: 1000;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 100%;
    max-height: 100%
  }
  
  .cdk-overlay-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;
    -webkit-transition: opacity .4s cubic-bezier(.25, .8, .25, 1);
    -o-transition: opacity .4s cubic-bezier(.25, .8, .25, 1);
    -moz-transition: opacity .4s cubic-bezier(.25, .8, .25, 1);
    transition: opacity .4s cubic-bezier(.25, .8, .25, 1);
    opacity: 0
  }
  
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1
  }
  
  @media screen and (-ms-high-contrast: active) {
    .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
      opacity: .6
    }
  }
  
  .cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, .288)
  }
  
  .cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0
  }
  
  .cdk-overlay-connected-position-bounding-box {
    position: absolute;
    z-index: 1000;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 1px;
    min-height: 1px
  }
  
  .cdk-global-scrollblock {
    position: fixed;
    width: 100%;
    overflow-y: scroll
  }
  
  .owl-dialog-container {
    position: relative;
    pointer-events: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    padding: 1.5em;
    -webkit-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);
    -moz-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);
    -moz-border-radius: 2px;
    border-radius: 2px;
    overflow: auto;
    background: #fff;
    color: rgba(0, 0, 0, .87);
    width: 100%;
    height: 100%;
    outline: 0
  }
  
  .owl-dt-container, .owl-dt-container * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
  }
  
  .owl-dt-container {
    display: block;
    font-size: 16px;
    font-size: 1rem;
    background: #fff;
    pointer-events: auto;
    z-index: 1000
  }
  
  .owl-dt-container-row {
    border-bottom: 1px solid rgba(0, 0, 0, .12)
  }
  
  .owl-dt-container-row:last-child {
    border-bottom: none
  }
  
  .owl-dt-calendar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%
  }
  
  .owl-dt-calendar-control {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1em;
    width: 100%;
    padding: .5em;
    color: #000
  }
  
  .owl-dt-calendar-control .owl-dt-calendar-control-content {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }
  
  .owl-dt-calendar-control .owl-dt-calendar-control-content .owl-dt-calendar-control-button {
    padding: 0 .8em
  }
  
  .owl-dt-calendar-control .owl-dt-calendar-control-content .owl-dt-calendar-control-button:hover {
    background-color: rgba(0, 0, 0, .12)
  }
  
  .owl-dt-calendar-main {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0 .5em .5em;
    outline: 0
  }
  
  .owl-dt-calendar-view {
    display: block;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
  }
  
  .owl-dt-calendar-multi-year-view {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }
  
  .owl-dt-calendar-multi-year-view .owl-dt-calendar-table {
    width: -webkit-calc(100% - 3em);
    width: -moz-calc(100% - 3em);
    width: calc(100% - 3em)
  }
  
  .owl-dt-calendar-multi-year-view .owl-dt-calendar-table .owl-dt-calendar-header th {
    padding-bottom: .25em
  }
  
  .owl-dt-calendar-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0
  }
  
  .owl-dt-calendar-table .owl-dt-calendar-header {
    color: rgba(0, 0, 0, .4)
  }
  
  .owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-weekdays th {
    font-size: .7em;
    font-weight: 400;
    text-align: center;
    padding-bottom: 1em
  }
  
  .owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-calendar-table-divider {
    position: relative;
    height: 1px;
    padding-bottom: .5em
  }
  
  .owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-calendar-table-divider:after {
    content: '';
    position: absolute;
    top: 0;
    left: -.5em;
    right: -.5em;
    height: 1px;
    background: rgba(0, 0, 0, .12)
  }
  
  .owl-dt-calendar-table .owl-dt-calendar-cell {
    position: relative;
    height: 0;
    line-height: 0;
    text-align: center;
    outline: 0;
    color: rgba(0, 0, 0, .85);
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: transparent
  }
  
  .owl-dt-calendar-table .owl-dt-calendar-cell-content {
    position: absolute;
    top: 5%;
    left: 5%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 90%;
    height: 90%;
    font-size: .8em;
    line-height: 1;
    border: 1px solid transparent;
    -moz-border-radius: 999px;
    border-radius: 999px;
    color: inherit;
    cursor: pointer
  }
  
  .owl-dt-calendar-table .owl-dt-calendar-cell-out {
    opacity: .2
  }
  
  .owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
    border-color: rgba(0, 0, 0, .4)
  }
  
  .owl-dt-calendar-table .owl-dt-calendar-cell-selected {
    color: rgba(255, 255, 255, .85);
    background-color: #3f51b5
  }
  
  .owl-dt-calendar-table .owl-dt-calendar-cell-selected.owl-dt-calendar-cell-today {
    -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, .85);
    -moz-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, .85);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, .85)
  }
  
  .owl-dt-calendar-table .owl-dt-calendar-cell-disabled {
    cursor: default
  }
  
  .owl-dt-calendar-table .owl-dt-calendar-cell-disabled > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
    color: rgba(0, 0, 0, .4)
  }
  
  .owl-dt-calendar-table .owl-dt-calendar-cell-disabled > .owl-dt-calendar-cell-content.owl-dt-calendar-cell-selected {
    opacity: .4
  }
  
  .owl-dt-calendar-table .owl-dt-calendar-cell-disabled > .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
    border-color: rgba(0, 0, 0, .2)
  }
  
  .owl-dt-calendar-table .owl-dt-calendar-cell-active:focus > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected), .owl-dt-calendar-table :not(.owl-dt-calendar-cell-disabled):hover > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
    background-color: rgba(0, 0, 0, .04)
  }
  
  .owl-dt-calendar-table .owl-dt-calendar-cell-in-range {
    background: rgba(63, 81, 181, .2)
  }
  
  .owl-dt-calendar-table .owl-dt-calendar-cell-in-range.owl-dt-calendar-cell-range-from {
    -moz-border-radius-topleft: 999px;
    border-top-left-radius: 999px;
    -moz-border-radius-bottomleft: 999px;
    border-bottom-left-radius: 999px
  }
  
  .owl-dt-calendar-table .owl-dt-calendar-cell-in-range.owl-dt-calendar-cell-range-to {
    -moz-border-radius-topright: 999px;
    border-top-right-radius: 999px;
    -moz-border-radius-bottomright: 999px;
    border-bottom-right-radius: 999px
  }
  
  .owl-dt-timer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 7em;
    padding: .5em;
    outline: 0
  }
  
  .owl-dt-timer-box {
    position: relative;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 25%;
    height: 100%
  }
  
  .owl-dt-timer-content {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    margin: .2em 0
  }
  
  .owl-dt-timer-content .owl-dt-timer-input {
    display: block;
    width: 2em;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, .5);
    -moz-border-radius: 3px;
    border-radius: 3px;
    outline: medium none;
    font-size: 1.2em;
    padding: .2em
  }
  
  .owl-dt-timer-divider {
    display: inline-block;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    position: absolute;
    width: .6em;
    height: 100%;
    left: -.3em
  }
  
  .owl-dt-timer-divider:after, .owl-dt-timer-divider:before {
    content: '';
    display: inline-block;
    width: .35em;
    height: .35em;
    position: absolute;
    left: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: currentColor
  }
  
  .owl-dt-timer-divider:before {
    top: 35%
  }
  
  .owl-dt-timer-divider:after {
    bottom: 35%
  }
  
  .owl-dt-control-button {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: 0;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    margin: 0;
    padding: 0;
    background-color: transparent;
    font-size: 1em;
    color: inherit
  }
  
  .owl-dt-control-button .owl-dt-control-button-content {
    position: relative;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    outline: 0
  }
  
  .owl-dt-control-period-button .owl-dt-control-button-content {
    height: 1.5em;
    padding: 0 .5em;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-transition: background-color .1s linear;
    -o-transition: background-color .1s linear;
    -moz-transition: background-color .1s linear;
    transition: background-color .1s linear
  }
  
  .owl-dt-control-period-button:hover > .owl-dt-control-button-content {
    background-color: rgba(0, 0, 0, .12)
  }
  
  .owl-dt-control-period-button .owl-dt-control-button-arrow {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 1em;
    height: 1em;
    margin: .1em;
    -webkit-transition: -webkit-transform .2s ease;
    transition: -webkit-transform .2s ease;
    -o-transition: transform .2s ease;
    -moz-transition: transform .2s ease, -moz-transform .2s ease;
    transition: transform .2s ease;
    transition: transform .2s ease, -webkit-transform .2s ease, -moz-transform .2s ease
  }
  
  .owl-dt-control-arrow-button .owl-dt-control-button-content {
    padding: 0;
    -moz-border-radius: 50%;
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em
  }
  
  .owl-dt-control-arrow-button[disabled] {
    color: rgba(0, 0, 0, .4);
    cursor: default
  }
  
  .owl-dt-control-arrow-button svg {
    width: 50%;
    height: 50%;
    fill: currentColor
  }
  
  .owl-dt-inline-container, .owl-dt-popup-container {
    position: relative;
    width: 18.5em;
    -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
    -moz-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12)
  }
  
  .owl-dt-inline-container .owl-dt-calendar, .owl-dt-inline-container .owl-dt-timer, .owl-dt-popup-container .owl-dt-calendar, .owl-dt-popup-container .owl-dt-timer {
    width: 100%
  }
  
  .owl-dt-inline-container .owl-dt-calendar, .owl-dt-popup-container .owl-dt-calendar {
    height: 20.25em
  }
  
  .owl-dt-dialog-container {
    max-height: 95vh;
    margin: -1.5em
  }
  
  .owl-dt-dialog-container .owl-dt-calendar {
    min-width: 250px;
    min-height: 330px;
    max-width: 750px;
    max-height: 750px
  }
  
  .owl-dt-dialog-container .owl-dt-timer {
    min-width: 250px;
    max-width: 750px
  }
  
  @media all and (orientation: landscape) {
    .owl-dt-dialog-container .owl-dt-calendar {
      width: 58vh;
      height: 62vh
    }
    .owl-dt-dialog-container .owl-dt-timer {
      width: 58vh
    }
  }
  
  @media all and (orientation: portrait) {
    .owl-dt-dialog-container .owl-dt-calendar {
      width: 80vw;
      height: 80vw
    }
    .owl-dt-dialog-container .owl-dt-timer {
      width: 80vw
    }
  }
  
  .owl-dt-container-buttons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 2em;
    color: #3f51b5
  }
  
  .owl-dt-container-control-button {
    font-size: 1em;
    width: 50%;
    height: 100%;
    -moz-border-radius: 0;
    border-radius: 0
  }
  
  .owl-dt-container-control-button .owl-dt-control-button-content {
    height: 100%;
    width: 100%;
    -webkit-transition: background-color .1s linear;
    -o-transition: background-color .1s linear;
    -moz-transition: background-color .1s linear;
    transition: background-color .1s linear
  }
  
  .owl-dt-container-control-button:hover .owl-dt-control-button-content {
    background-color: rgba(0, 0, 0, .1)
  }
  
  .owl-dt-container-info {
    padding: 0 .5em;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent
  }
  
  .owl-dt-container-info .owl-dt-container-range {
    outline: 0
  }
  
  .owl-dt-container-info .owl-dt-container-range .owl-dt-container-range-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: .5em 0;
    font-size: .8em
  }
  
  .owl-dt-container-info .owl-dt-container-range:last-child {
    border-top: 1px solid rgba(0, 0, 0, .12)
  }
  
  .owl-dt-container-info .owl-dt-container-info-active {
    color: #3f51b5
  }
  
  .owl-dt-container-disabled, .owl-dt-trigger-disabled {
    opacity: .35;
    filter: Alpha(Opacity=35);
    background-image: none;
    cursor: default !important
  }
  
  .owl-dt-timer-hour12 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #3f51b5
  }
  
  .owl-dt-timer-hour12 .owl-dt-timer-hour12-box {
    border: 1px solid currentColor;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-transition: background .2s ease;
    -o-transition: background .2s ease;
    -moz-transition: background .2s ease;
    transition: background .2s ease
  }
  
  .owl-dt-timer-hour12 .owl-dt-timer-hour12-box .owl-dt-control-button-content {
    width: 100%;
    height: 100%;
    padding: .5em
  }
  
  .owl-dt-timer-hour12 .owl-dt-timer-hour12-box:focus .owl-dt-control-button-content, .owl-dt-timer-hour12 .owl-dt-timer-hour12-box:hover .owl-dt-control-button-content {
    background: #3f51b5;
    color: #fff
  }
  
  .owl-dt-calendar-only-current-month .owl-dt-calendar-cell-out {
    visibility: hidden;
    cursor: default
  }
  
  .owl-dt-inline {
    display: inline-block
  }
  
  .owl-dt-control {
    outline: 0;
    cursor: pointer
  }
  
  .owl-dt-control .owl-dt-control-content {
    outline: 0
  }
  
  .owl-dt-control:focus > .owl-dt-control-content {
    background-color: rgba(0, 0, 0, .12)
  }
  
  .owl-dt-control:not(:-moz-focusring):focus > .owl-dt-control-content {
    -moz-box-shadow: none;
    box-shadow: none
  }
  
  .owl-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
  }
  
  .modal-backdrop.show {
    z-index: 1 !important;
  }
  
  .cdk-overlay-container {
    position: fixed;
    z-index: 20 !important;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10 !important;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
  }
  

  @media (max-height: 500px) {
    .mat-mdc-calendar {
      max-height: 210px;
      overflow-y: auto;
    }
  }
  

  .custom-btn {
    &.mdc-button {
      border: 1px solid var(--app-primary-color)!important;
    }
  }