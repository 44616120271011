.image-wrap {
  width: 120px;
  height: 120px;
  box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.35);
  &:hover {
    box-shadow: 0 0px 2px 0 var(--app-primary-color);
  }
}

.upload-area {
  button {
    padding: 0;
    border: 0 !important;
    color: var(--app-primary-color) !important;
    min-width: initial;
    &:hover {
      border-color: var(--app-primary-color) !important;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .add-button {
    z-index: 2;
    .btn-primary {
      display: block;
      height: 100%;
      background: url('/assets/images/ImagePlaceholder.png') center no-repeat !important;
      font-size: 0;
      width: 100%;
      background-size: 55px !important;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .action-btn {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin: 6px 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.35);

    fa-icon {
      font-size: 14px;
    }
  }

  .update-btn {
    .btn-primary {
      width: 100%;
      height: 100%;
      font-size: 0;
      background: url('/assets/images/edit.svg') center no-repeat !important;
      background-size: 15px !important;
    }
  }

  img {
    top: 0;
    left: 0;
  }

  &:hover {
    .action-btn {
      z-index: 1;
    }
  }
}

/*==== Gallery dialog ===*/

.gallery-dialog {
  width: 100%;
  max-width: 1020px !important;
  margin: 0 auto;
  max-height: 650px;
  height: 100%;

  .mat-mdc-dialog-container {
    display: flex;
    // padding-bottom: 4rem;
    max-height: 90vh;
    margin: 0 auto;
    width: 100%;
  }

  .mat-mdc-dialog-surface {
    overflow-y: hidden;
  }
  .app-dialog, .mdc-dialog__container {
    width: 100%;
  }

  .header-actions {
    button {
      outline: none !important;
    }
  }

  .mat-mdc-tab-header {
    border: 0;
  }

  .mat-mdc-tab-labels {
    justify-content: center;
    border-bottom: 1px solid #dee2e6;
  }

  .mat-mdc-tab-group {
    display: block;
    font-family: var(--app-font-primary);
  }

  .mat-mdc-tab-list {
    margin-bottom: 2rem;
  }

  .mat-mdc-tab-label-content {
    font-size: 1.2rem;
    color: var(--app-text-color);
    font-weight: 500;
  }

  .mat-mdc-ink-bar {
    display: none;
  }

  .mat-mdc-tab-label {
    .mdc-tab{
    opacity: 1;
    font-family: var(--app-font-primary);

    &:before {
      content: '';
      position: absolute;
      background: var(--app-primary-color);
      width: 0;
      opacity: 0;
      height: 2px;
      bottom: 0;
      left: 0;
      transition: opacity 0.1s, width 0.3s ease;
    }

    &:hover:before {
      opacity: 1;
      width: 100%;
    }
  }
  }

  .mat-mdc-tab-label-active {
    &:before {
      opacity: 1;
      width: 100%;
    }

    .mat-mdc-tab-label-content {
      color: var(--app-default-color);
    }
  }

  .file-select-container {
    height: 100%;
  }

  ngx-dropzone {
    height: 100%;
    border-color: var(--app-text-light);

    &:hover {
      border-color: var(--app-info-color);
    }
  }

  .dialog-container,
  .mat-mdc-tab-group,
  .mat-mdc-tab-body-wrapper {
    height: 95%;
  }

  .tabs {
    height: 100%;
  }

  .mat-tab-group {
    display: flex;
  }
}

.image-row {
  margin: 0 -5px;
  .image-col {
    padding: 5px;
  }
}

.dialog-container {
  .mat-mdc-progress-bar {
    position: absolute !important;
    top: 30px !important;
    left: 25px;
    right: 25px;
    width: auto;
  }
}

@media(max-width: 767px) {
  .mat-mdc-dialog-container {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}
