.mat-mdc-snack-bar-container {
  border-radius: 5px;
  .mdc-snackbar__surface {
    background-color: transparent !important;
  }
  .mat-mdc-button-wrapper {
    color: white;
  }
  &.toast-error {
    background: var(--app-danger-color);
  }

  &.toast-info {
    background: $blue;
  }

  &.toast-warning {
    background: var(--app-warning-color);
  }

  &.toast-success {
    background: var(--app-success-color);
  }

  &.toast-default {
    background: inherit;
  }
}
