// buttons

button:focus {
  outline: none;
  box-shadow: none !important;
}

.btn {
  padding: 11px 3.11rem 13px;
  font-size: 14px;
  border-radius: 0;
  -webkit-font-smoothing: initial;
  border: 2px solid;
  text-decoration: none;

  &.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
}

.btn-transparent {
  background: transparent !important;
}

.btn-common {
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 4px 15px;
  line-height: 1.89;
  min-width: 100px !important;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  font-weight: 500;
  border: 1px solid transparent;
}

.portal-bg {
  background: var(--app-primary-color);
}

.btn-primary {
  color: #fff !important;
  background: var(--app-primary-color) !important;
  border: 1px solid var(--app-primary-color) !important;
  &:hover,
  &:active,
  &:focus {
    color: var(--app-primary-color) !important;
    background: transparent !important;
  }
  i {
    vertical-align: middle;
  }
}

.btn-outline-primary {
  color: var(--app-primary-color);
  border-color: var(--app-primary-color);
  &:hover,
  &:active,
  &:focus {
    background-color: var(--app-primary-color) !important;
    border-color: var(--app-primary-color) !important;
    color: #fff !important;
  }
}

.btn-secondary {
  color: #fff !important;
  background: var(--app-gray-2) !important;
  border: 1px solid var(--app-gray-2) !important;
  &:hover,
  &:active {
    color: var(--app-gray-2) !important;
    background: transparent !important;
  }
}

.show > .btn-outline-primary.dropdown-toggle {
  background-color: var(--app-primary-color) !important;
  border-color: var(--app-primary-color) !important;
  color: #fff !important;
}

.btn-outline {
  color: #000 !important;
  font-size: 14px;
  border: 2px solid #e3e3e4;
}

.btn-link {
  color: var(--app-primary-color);
  background: transparent;
  border: 0;
  text-decoration: none !important;
}

.dropdown-item {
  &.active,
  &:active {
    background-color: var(--app-primary-color);
    button {
      color: #fff;
    }
  }
}

.more-options {
  .dropdown-menu {
    min-width: initial;
    width: 150px;
    padding: 2px 0;
    border: 0;
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14);
  }
}

.btn-donate {
  border-radius: 25px;
  background: #fff;
  font-weight: 500;
  color: #484848;
  padding-left: 60px;
  overflow: hidden;
  box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.14);
  padding: 10px 18px 12px 60px;
  transition: 0.3s ease;
  span {
    top: 0;
    left: 0;
    width: 50px;
    background: var(--app-primary-color);
  }
  img {
    max-width: 21px;
  }
  &:hover {
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  }
}

@media (min-width: 1024px) {
  .btn-outline {
    &:hover {
      color: #fff !important;
      background: var(--app-primary-color);
      border-color: var(--app-primary-color);
    }
  }
}
