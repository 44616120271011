.content-cover {
  @include flex-cover;
}
.fw-500 {
  font-weight: 500 !important;
}
.custom-field:has( .invalid-feedback) {
  .mat-mdc-form-field-wrapper, .mat-mdc-text-field-wrapper {
    border: 1px solid red;
  }
  mat-form-field {
    margin-bottom: 0;
  }
}

.custom-field mat-label {
  font-size: 14px;
}

pre {
  font-family: var(--app-font-primary);
  font-size: 1rem;
  white-space: pre-wrap;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.box-shadow {
  box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.08);
}

.v-align-middle {
  vertical-align: middle;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-auto {
  cursor: auto;
}

.word-break {
  word-break: break-word;
  overflow-wrap: break-word;
}

.main-content {
  &:not(.content-right) {
    .page-header .title {
      padding-left: 0 !important;
    }
  }
}

.page-container {
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.navbar-brand {
  max-width: 230px;
  min-width: 150px;

  img {
    object-fit: contain;
  }
}

.navbar-toggler {
  left: 0;
  top: 15px;
  padding: 0;
  z-index: 1;
  transition: 0.3s ease;

  &:focus {
    outline: none;
  }

  span {
    background: #333 !important;
    height: 2px;
    width: 32px;
    display: block;
    -webkit-transition: all .35s ease;
    transition: all .35s ease;
    cursor: pointer;
    margin-top: 5px;

    &:first-of-type {
      margin-top: 0;
    }
  }
}
.mat-mdc-form-field, .mat-mdc-form-field:hover, .mdc-text-field--filled:not(.mdc-text-field--disabled), .mat-mdc-text-field-wrapper:hover, .mat-mdc-form-field-focus-overlay {
  background: transparent !important;
}

.sidebar {
  .portals-menu {
    display: inline-block;

    &:before {
      width: 5px !important;
      right: -15px !important;
    }
  }

  select {
    border: 0;
    outline: 0;
    font-size: 16px;
    padding: 5px 0 !important;
    font-weight: 600;
  }
}

.card {
  border-radius: 6px;
}

.page-header {
  h2 {
    font-weight: 500;
    text-transform: capitalize;
  }

  .title {
    a {
      color: var(--app-default-color);
    }
  }

  .file-export-btn {
    font-size: 1.5rem;
  }
}

address {
  max-width: 230px;
}

.flex-1 {
  flex: 1;
}

/*=== images ===*/

.img-wrap {
  img {
    top: 0;
    left: 0;
    position: absolute;
  }
}

/*===== pagination ====*/
.page-item {
  font-size: 14px;
  transition: all .3s ease-in-out;

  &.active {
    .page-link {
      background-color: var(--app-primary-color) !important;
      color: #fff !important;
    }
  }

  &.disabled {
    .page-link {
      color: var(--app-text-color);
    }
  }
  .page-link {
    padding: 9px 17.5px;
    color: #4a90e2;
    background: #f9f9f9 !important;
    border: 1px solid #ced4da !important;
    transition: all .3s ease-in-out;
    &:hover {
      color: #0056b3;
      background: #e9ecef !important;
    }
  }
}

.pagination {
  margin-bottom: 0;
  border-radius: 4px;
}

.page-dropdown {
  background: #fff;
  width: 85px;

  select {
    width: 100%;
  }
}

.mat-mdc-paginator-container {
  justify-content: flex-start !important;

  .mat-mdc-icon-button {
    width: 20px;
    height: auto;
    color: var(--app-primary-color);
  }
}

// .page-item.active .page-link {
//   background-color: var(--app-primary-color) !important;
//   border-color: var(--app-primary-color) !important;
// }

.mat-mdc-form-field, .mat-mdc-form-field, .mat-mdc-floating-label{
  font-family: var(--app-font-primary) !important;
}

// ticket cta css

.ticket-cta {
  margin-left: auto;
  margin-right: 1rem;

  button {
    font-size: 20px;
  }
}

//Fundraiser Information Modal
.modal:has( .modal-header.foundation-modal) {
  position: relative;
  display: flex !important;
  justify-content: center;
  align-items: center;

  .modal-dialog {
    margin: 0 !important;
    max-width: 1100px;
    padding: 0 15px;

    .modal-content {
      height: 420px;
    }
  }
  .modal-body {
    padding: 0;
    overflow: auto;
    p {
      margin-bottom: 0;
    }
  }
}

.scroll-area {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d8d8d8;
    border-radius: 8px;
  }
}

.img-round {
  width: 30px;
  height: 30px;
  overflow: hidden;
}

.token-item {
  display: inline-flex;
  border-radius: 27px;
  padding: 2px 12px;
  align-items: center;
  border: solid 1px #ddd;
  margin: 0 0 15px;
  height: 44px;
  font-size: 12px;
  min-width: 150px;

  .icon {
    width: 30px;
    height: 30px;
    overflow: hidden;

    img {
      object-fit: cover;
    }
  }

  .label {
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 1.55;
    font-weight: 500;
  }

  &:not(:last-child) {
    margin-right: 12px;
  }

  .mat-mdc-form-field{
    padding: 9px 0 0;
  }

  .mat-mdc-form-field-flex {
    align-items: flex-start;
    border: 0;
  }

  .mat-mdc-form-field-infix {
    width: auto;
    border: 0;
  }

  .mat-mdc-form-field-label-wrapper {
    top: -.64375em;
  }

  .mat-mdc-form-field-infix,
  .mat-mdc-form-field-wrapper {
    padding: 0 !important;
  }

  input {
    font-size: 12px !important;
    font-weight: 600;
  }

  .mat-mdc-form-field-label,
  .mat-mdc-form-field-underline {
    display: none;
  }

  .mat-mdc-form-field-suffix {
    order: -1;
    padding-right: 8px;

    .mat-mdc-icon-button {
      height: 30px;
      width: 30px;
    }
  }

  .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-label {
    font-size: 12px;
    color: var(--app-default-color);
    top: 0 !important;
    width: auto !important;
    transform: translateY(0) scale(1) perspective(100px) translateZ(0) !important;
  }
}

.tokens {
  .btn-common {
    min-width: 150px;
    margin: 0 0 15px;
  }
}

.neg-margin {
  .mat-mdc-form-field {
    margin-bottom: 0;
  }
}

.assign-token {
  min-width: 175px;
  // max-width: 210px;
  max-width: 100%;
  font-size: 12px;
  font-weight: 600;
  position: relative;
  cursor: pointer;

  .mat-mdc-autocomplete-trigger[aria-expanded="true"], .mat-mdc-autocomplete-trigger {
    padding-top: 10px !important;
    font-weight: 700 !important;
  }
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 30px !important;
  }
  .icon {
    position: absolute;

    +span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 190px;
      padding-left: 40px;
    }
  }

  &:hover {
    border-color: var(--app-text-color);
  }

  .mat-mdc-form-field-label {
    top: 14px;
    top: 10px;
    width: auto !important;
    transform: translateY(0) scale(1) perspective(100px) translateZ(0) !important;
  }

  .mat-mdc-form-field-flex {
    padding-left: 25px;
  }
  // &.quick-assign {
  //   .mat-mdc-form-field-flex {
  //     padding-left: 40px;
  //   }
  // }

  .mat-mdc-form-field-infix {
    padding: 8px 0 0 !important;
  }

  .view {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 190px;
    padding-left: 30px;
  }
}
.card-body:has(.comment-area) {
  overflow-x: hidden;
}
.milege-form {
	.mat-mdc-form-field-flex, .mat-mdc-form-field {
		border: 1px solid transparent;
	}
}
.mdc-line-ripple{
  &:after,
  &:before{
    display: none;
  }
}
.filter-area, .custom-field {
  .mat-mdc-icon-button.mat-mdc-button-base {
    padding: 0 !important;
    width: 20px !important;
  }
  .mat-mdc-icon-button svg, .mat-mdc-icon-button img {
    width: 16px !important;
    height: 16px !important;
  }
}
.mat-ripple.mat-mdc-button-ripple {
  display: none !important;
}
.date-field {
  &:not(.recurring-date-field){
    svg {
      margin-top: -10px !important;
    }
  }
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 30px !important;
  }
}
.date-token {
  max-width: 140px;
  cursor: pointer;

  &:hover {
    border-color: var(--app-text-color);
  }

  .mat-mdc-form-field-flex {
    padding-top: 5px;
  }
  .mat-mdc-text-field-wrapper {
    padding-left: 0;
  }
  .mat-datepicker-toggle {
    margin-left: -15px;
  }
  .mat-datepicker-input {
    width: 75px;
    padding-top: 5px;
    font-weight: 700 !important;
  }
  mat-label {
    font-weight: 400 !important;
    color: #000 !important;
  }
  .mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
    background-color: transparent;
  }
  .mat-mdc-form-field-icon-suffix {
    order: 1;
  }
  .mat-mdc-form-field-infix {
    order: 2;
  }
  svg {
    color: var(--app-text-color);
    width: 24px !important;
  }

  .mat-mdc-form-field{
    padding: 4px 0 0;
  }

  input {
    margin-top: 12px !important;
  }

  .mat-mdc-form-field-label-wrapper {
    top: -4px;
  }
}

.list-wrap {
  height: calc(100% - 60px)

}
.padding-0 {
    .mat-mdc-text-field-wrapper {
        padding: 0 !important;
    }
    .mat-mdc-input-element {
      padding: 5px !important;
    }
}
.lists {
  max-height: calc(100% - 58px);
  padding: 2px;

  &.open {
    max-width: 43%;
    max-height: 100% !important;

    .project-name,
    .entity-name {
      flex: 0 0 85%;
      max-width: 85%;
    }

    .more-details, .list-data {
      display: none !important;
    }
  }
}
//for validation alignment in case when error-component is out of the form-component
.validation-align .invalid-feedback {
  margin: -10px 0 10px 0;
}

.online-form .mat-mdc-input-element:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
  width: 100%;
  border:  var(--bs-border-width) solid var(--bs-border-color);
}
.mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before,
.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before,
.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  background-color: transparent !important;
}
.mat-mdc-form-field-infix {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--filled.mdc-text-field--no-label {
  padding: 0;
}

.custom-field .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--filled.mdc-text-field--no-label {
  padding: 0 15px;
}

.item {
  min-height: 65px;
  background: #fff;
  box-shadow: 1px -1px 5px 0 rgba(0, 0, 0, 0.08);
  padding: 2px 50px 2px 25px;
  border-radius: 6px;
  color: var(--app-text-color);
  flex-wrap: wrap;
  position: relative;

  .entity-name {
    display: flex;
    align-items: center;
    line-height: 1.25;

    fa-icon {
      width: 45px;
    }

    span {
      flex: 1;
    }
  }

  &.selected {
    background: #eaf7ff;
  }

  cursor: pointer;

  .arrow-right {
    font-size: 28px;
    right: 20px;
    transition: all 0.25s ease;
    color: var(--app-text-color);
  }

  &:not(.no-hover):hover {
    background: #eaf7ff;

    .arrow-right {
      color: var(--app-default-color);
      transform: translateX(6px);
    }
  }

  .more-details {
    >div {
      width: 20%;
      word-break: break-word;
    }
  }
}

.project-input-wrap:has(.is-invalid) .mat-mdc-form-field-wrapper {
  border-color: red;
}

.list-header {
  background: #f6f6f6 !important;
  color: var(--app-heading-color);
  min-height: 50px;
  cursor: auto;

  +.lists {
    max-height: calc(100% - 128px);
  }

  &.hide {
    display: none !important;
  }
}

.pagination-wrap {
  padding-bottom: 15px;
  flex-wrap: wrap;
}
.text-dark {
  color: #333 !important;
}
.text-disabled-light {
  color: #00000061;
}

.detail-wrap {
  background: #fff;
  padding: 15px 2.3rem 20px;
  border-radius: 6px;
  box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.08);
  transition: transform .2s ease-in, -webkit-transform .2s ease-in;

  .user-img {
    width: 2.6rem;
    height: 2.6rem;
  }

  &.showfull {
    width: 100%;
  }

  .scroll-area {
    max-height: calc(100% - 125px);

    &.tickets-scrollarea {
      max-height: calc(100% - 40px);
    }
  }
}

.detail-content {
  .sm-content {
    max-width: 560px;
  }

  .info-wrap {
    padding: 0 15px;
  }

  .mb-3 {
    margin-bottom: 1.5rem;
  }
}

.detail-right {
  position: absolute;
  transform: translateX(101%);
  right: -15px;
  top: 0;
  width: 57%;
  bottom: 0;
  z-index: 3;

  &.tickets-detailright {
    min-height: 72vh;
  }


  label {
    font-weight: 500;
  }

  &.open {
    transform: translateX(0);
    transition: all .5s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform .5s cubic-bezier(0.23, 1, 0.32, 1);
  }
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
  -webkit-font-smoothing: initial;
}

/*=== add project settings ====*/
.modal {
  z-index: 99 !important;
}

.modal-dialog {
  max-width: 790px;

  .modal-content {
    max-height: 90vh;
    overflow-y: auto;
  }
}

.donate-modal {
  .img-wrap {
    padding-top: 70%;
    min-height: 480px;
  }

  iframe {
    top: 0;
    left: 0;
  }
}

/*===== user profile ===*/

.settings-wrap {
  background: #fff;
  padding: 30px;

  .settings-col {
    width: 300px;
  }

  .content-col {
    width: calc(100% - 320px);
  }
}

.box-wrap {
  background: #fff;
  padding: 25px 2.5rem;
  box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}

/*===== mat dropdown ====*/

.mat-mdc-option:focus:not(.mat-mdc-option-disabled),
.mat-mdc-option:hover:not(.mat-mdc-option-disabled),
.cdk-keyboard-focused .mat-mdc-calendar-body-active>.mat-mdc-calendar-body-cell-content:not(.mat-mdc-calendar-body-selected),
.cdk-program-focused .mat-mdc-calendar-body-active>.mat-mdc-calendar-body-cell-content:not(.mat-mdc-calendar-body-selected),
.mat-mdc-calendar-body-cell:not(.mat-mdc-calendar-body-disabled):hover>.mat-mdc-calendar-body-cell-content:not(.mat-mdc-calendar-body-selected) {
  background: var(--app-hover-blue) !important;
}
.mat-mdc-form-field:not([class*="col-md"]){
  width: 100%;
}
// .mat-mdc-form-field{
//   width: 100%;
// }
.mdc-form-field {
  button, button:hover {
    .mdc-switch__ripple::after{
      background-color: transparent !important;
    }
  }
}

.mat-mdc-menu-trigger {
  .mdc-button__ripple::before{
    background-color: transparent !important;
  }
}

.mat-mdc-autocomplete-panel {
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d8d8d8;
    border-radius: 8px;
  }
}
.tooltip-icon {
  background: transparent;
  border: none;
  .mat-icon {
    width: 18px;
    height: 18px;
    font-size: 17px;
  }
}
.cdk-overlay-pane {
  margin: 15px 0;
  min-width: 230px;
  max-height: 90vh;
  // max-width: 98% !important;
  @media(min-width: 992px){
    &:has(.calendar-modal) {
      width: 790px;
    }
  }
  .mat-mdc-dialog-container {
    .mdc-dialog__container {
      width: 100%;
      .mat-mdc-dialog-surface{
        padding: 24px;
        &:has( .calendar-header) {
          padding: 0 !important;
        }
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d8d8d8;
      border-radius: 8px;
    }
  }
  }
  }

  .mat-mdc-autocomplete-panel {
    min-width: 210px;
  }

  .mat-mdc-option-text {
    font-size: 12px;
    font-weight: 600;
  }
}

.mat-mdc-option {
  span{
    font-family: var(--app-font-primary) !important;
    font-size: 12px !important;
    font-weight: 600 !important;
  }
}

.mdc-switch__icons {
  display: none;
}

/*===== filter section =====*/

.filter-toggle {
  font-size: 16px;
  color: var(--app-default-color);
  transition: 0.3s ease;

  &:hover {
    color: var(--app-primary-color);
  }

  &:focus {
    outline: none;
  }

  &.close-btn {
    transform: translateX(-265px);

    .filter-icon {
      display: none;
    }

    .close-icon {
      display: block !important;
      font-size: 20px;
    }
  }
}

.close {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}


.filter-area {
  position: fixed;
  padding: 2rem 15px 1rem;
  top: 77px;
  right: 0;
  width: 280px;
  height: calc(100% - 77px);
  background: #fff;
  z-index: 11;
  overflow-y: auto;
  transform: translateX(280px);
  transition: 0.3s ease;

  &.open {
    transform: translate(0);
  }

  .search-input {
    input {
      padding-right: 30px;
    }
  }

  .mb-3 {
    margin-bottom: 1.3rem;
  }

  .btn-sm {
    height: 37px;
    min-width: calc(50% - 0.5rem);
  }
}


/*=== online forms ===*/

.field-divider {
  border: 1px solid #eaeaea;
  padding: 0 30px 20px;
  box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.08);

  .title {
    display: inline-block;
    position: relative;
    top: -20px;
    margin-bottom: 0;
    padding: 10px 20px;
    min-height: 26px;
    vertical-align: sub;
    color: #fff;
    background: var(--app-primary-color);
    font-size: 17px;
    line-height: 1.4;
    border: 1px solid var(--app-primary-color);
    border-radius: 4px;
    font-weight: 400;
    -webkit-font-smoothing: initial;
  }

  .subtitle {
    font-size: 16px;
    line-height: 1.4;
    padding: 7px 15px;
    background: #fff;
    color: #000;
  }
}

.form-card {
  .nav-tabs {
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    a {
      color: var(--app-text-color);
      font-size: 1.2rem;
      font-weight: 500;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        background: var(--app-primary-color);
        width: 0;
        opacity: 0;
        height: 2px;
        bottom: 0;
        left: 0;
        transition: opacity 0.1s, width 0.3s ease;
      }

      &:hover:before {
        opacity: 1;
        width: 100%;
      }

      &.active {
        color: var(--app-default-color);
        border-color: inherit !important;

        &:before {
          opacity: 1;
          width: 100%;
        }
      }
    }

    li {
      min-width: 140px;

    }
  }
}

.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused,
.mat-step-header:hover,
.mdc-text-field--disabled.mdc-text-field--filled {
  background: transparent !important;
}

.mat-step-icon {
  height: 30px !important;
  width: 30px !important;
  background-color: var(--app-gray-color) !important;

  &.mat-step-icon-selected {
    background-color: var(--app-primary-color) !important;
  }
}

.ticket-details {
  .row.border-bottom {
    border-bottom: 2px solid #f0f0f0 !important;
  }

  .title {
    font-size: 20px;
  }

  label {
    margin: 0;
    border-right: 2px solid #f0f0f0
  }
}

.detail-wrapper {

  .mat-mdc-tab-body-wrapper,
  .mat-mdc-tab-body {
    height: 100%;
  }

  .mat-mdc-tab-header {
    z-index: 1;
  }

  .mat-mdc-tab-body:first-child {
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d8d8d8;
      border-radius: 8px;
    }
  }
}

.modal-header {
  border-bottom: 1px solid #dee2e6;
  button {
    outline: none !important;

    fa-icon {
      font-size: 17px;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}

.modal-body {
  .users {
    font-size: 14px;
  }
}

// Dropdown Menu CSS
.dropdown-list.mat-mdc-menu-panel {
  box-shadow: 0 5px 13px 0 var(--app-text-light);
  border: 0;
  border-radius: 4px;
  padding: 30px 20px;
  left: auto;
  right: 0;
  overflow-y: auto;
  max-height: 85vh;
  min-width: 275px;
  .mat-mdc-menu-item {
    padding: 0;
    min-height: auto;
  }
  .mat-mdc-menu-content {
    padding: 0;
  }
  span.mat-mdc-menu-item-text {
    font-size: 14px;
    padding: 8px 5px !important;
    color: var(--app-primary-color) !important;
    line-height: 15.4px;
    font-family: var(--app-font-primary);
    cursor: pointer;
    &:active {
      background-color: #f8f9fa;
    }
  }
  .user-menu-button:hover {
    background-color: transparent;
  }
}

.assign-token .mdc-floating-label:not(.mdc-floating-label--float-above ){
  margin-top: -15px;
  mat-label {
    font-size: 12px !important;
  }
}

.assign-token .mdc-floating-label {
  mat-label {
    font-weight: 400;
    color: #000;
  }
}

// thank you and error page

.line-break {
  &:before {
    position: absolute;
    content: '';
    background: var(--app-primary-color);
    height: 11px;
    width: 70px;
    bottom: -23px;
  }
}

.cdk-overlay-container {
  z-index: 100 !important;
}

.modal {
  +.cdk-overlay-container {
    z-index: 100 !important;
  }
}

//After login Modal
.custom-afterlogin-modal-class {
  .modal-dialog {
    max-width: 1200px;
    margin-top: 124px;

    .modal-content {
      overflow-y: initial;
      background-color: transparent;
      border: none;
    }
  }

  .slick-dotted.slick-slider {
    margin-bottom: 0;
  }

  .slick-dots {
    bottom: 33% !important;

    li {
      &.slick-active button:before {
        background: #006CC3 !important;
      }

      button:before {
        content: "" !important;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.9);
        opacity: 1;
      }
    }
  }

  .slick-prev:before {
    content: url("/assets/images/modal-left-arrow.png");
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
  }

  .slick-next:before {
    content: url("/assets/images/modal-right-arrow.png");
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
  }

  .slick-prev,
  .slick-next {
    height: 98px;
    width: 60px;
    z-index: 99;
    background: rgba(0, 0, 0, 0.3);
    top: 31%;
    -webkit-transform: translate(0, -31%);
    transform: translate(0, -31%);
  }

  .slick-next {
    right: 0;
  }

  .slick-prev {
    left: 0;
  }

}


body.foundation-modal-open .page-container {
  position: fixed;
}

/*==== responsive ======*/

@media (min-width: 1699px) {
  .lists.open {
    max-width: 46%;
  }

  .detail-right {
    width: 54%;
  }

}

@media(min-width: 1600px) {
  .custom-afterlogin-modal-class {
    .modal-dialog {
      max-width: 1400px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media (min-width: 1200px) {
  .page-header {
    .search-box {
      max-width: 450px;
    }
  }

}

@media(min-width: 1200px) and (max-width: 1599px) {}

.ticket-listing-tabs {
  .item .entity-name {
    font-size: 14px;
  }

  .item .more-details>div {
    font-size: 12px;
    width: 10% !important;
  }
}

@media (max-width: 1450px) {

  .lists .more-details {
    font-size: 14px;
    font-weight: 500;
  }

  .item .entity-name {
    font-size: 16px;
  }

  .detail-wrap {
    padding: 15px 1rem 20px 1.8rem;
  }

  .custom-afterlogin-modal-class .slick-dots {
    bottom: 36% !important;
  }

  .card-heading {
    font-size: 19px;
  }
}


@media (max-width: 1199px) {
  html {
    font-size: 15px;
  }

  .page-header {
    .title {
      padding-left: 55px;
    }
  }

  .item .entity-name {
    max-width: 90%;
  }

  .scroll-area {
    overflow: initial;
  }

  .main-content {
    min-height: calc(100% - 73px);
    height: auto !important;
  }

  .detail-wrap .scroll-area {
    max-height: initial !important;
  }

  .item {
    padding: 10px 20px 10px 15px !important;
    height: auto;

    .more-details {
      padding-left: 41px;
    }

    .arrow-right {
      right: 12px;
      top: 20px;
    }

  }

  .detail-right {
    width: 96% !important;
    margin: 0 auto;
    position: fixed;
    overflow-y: auto !important;
    top: 78px;
    bottom: auto;
    left: 0;
    right: 0;
    height: calc(100% - 90px);
    z-index: 12;
  }

  .list-wrap {
    margin-bottom: 50px;
  }

  .custom-afterlogin-modal-class {
    .modal-dialog {
      margin-right: 100px;
      margin-left: 100px;
    }

    .slick-dots {
      bottom: 49% !important;
    }
  }
}

@media (max-width: 991px) {
  .item {
    min-height: 120px;

    .entity-name {
      padding: 0 0 4px !important;

      img:not(.img-round) {
        width: 37px;
        object-fit: contain;
      }

      fa-icon {
        width: 37px;
        padding: 0 !important;
      }
    }
  }
  .ticket-listing-tabs {
    .item {
      .more-details {
        flex: 0 0 100%;
        max-width: 100% !important;
        display: flex;
        flex-direction: column;
        align-items: flex-start !important;
        position: static;
        margin-left: 0 !important;
        padding-left: 38px;

        > div {
          padding: 0 0 4px !important;
          width: 100% !important;
        }
      }
    }
  }

  .filter-section {
    position: fixed;
    background: #fff;
    bottom: 0;
    margin-bottom: 0 !important;
    z-index: 11;
    width: 100%;
    left: 0;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.35);

    .filter-toggle {
      transform: none !important;
    }
  }

  .filter-area {
    width: 100%;
    height: auto;
    top: 47px;
    bottom: 51px;
    box-shadow: none;
    transform: translateY(109%);
  }

  .custom-afterlogin-modal-class {
    .slick-dots {
      bottom: 58% !important;
    }

    .slick-prev,
    .slick-next {
      top: 41%;
    }
  }
}


@media (max-width: 767px) {

  .navbar-nav {
    flex-direction: row;
    padding: 0 1rem;

    .ng-star-inserted {
      padding: 0 !important;
    }

    // .portals-menu {
    //   display: none;
    // }

  }

  .form-wrap {
    padding: 10% 15px 2% !important;
  }

  .navbar-brand {
    width: 200px;
  }


  .page-container {
    position: static;
  }

  #wrapper {
    overflow-y: inherit;
  }

  .page-header {
    .title {
      align-items: flex-start !important;
      justify-content: space-between;
    }

    .tooltip {
      left: -4% !important;
    }
  }

  .pagination-wrap {
    justify-content: flex-start !important;

    .page-listing {
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  .tokens {
    flex-direction: column;

    .quick-view-btn {
      order: -1;
    }
  }

  .token-item {
    width: 100%;
    max-width: 240px !important;
    padding: 8px 12px 4px;

    input {
      font-size: 16px !important;
      transform: scale(0.75);
      transform-origin: top left;
    }
  }

  .modal {
    .modal-dialog {
      margin-top: 7rem;
    }
  }

  .nav-tabs {
    li {
      margin: 0 5px !important;
      padding: 0 10px;
    }

    a {
      font-size: 15px;
    }
  }

  .cdk-overlay-pane {
    margin: 15px 0;
  }



  .form-card {
    padding: 2.2rem 15px !important;
  }

  .comment-buttons .btn-common,
  .comment-buttons app-media-open-button {
    width: 100%;
    max-width: 195px;
    margin-right: 0 !important;
  }

  .field-divider {
    padding: 0 15px 20px;
  }

  .ticket-details label {
    border-right: 0;
  }

  .loading-wrap {
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    height: 100%;
  }

}

@media (max-width: 575px) {
  .custom-afterlogin-modal-class {
    .modal-dialog {
      margin-right: 15px;
      margin-left: 15px;
    }

    .slick-dots {
      bottom: 71% !important;

      li {
        margin: 0 0px;

        button:before {
          width: 10px;
          height: 10px;
        }
      }
    }

    .slick-prev:before,
    .slick-next:before {
      transform: scale(0.5);
    }

    .slick-prev,
    .slick-next {
      height: 50px;
      width: 50x;
      top: 32%;
    }
  }

  .navbar-brand {
    margin: 0 !important;
    width: 200px !important;
  }

  .dropdown-toggle.portal-bg {
    padding: 0 !important;
    // width: 50px !important;
  }

}

.btn-disabled{
  background-color: #fff !important;
  color: #828282 !important;
  cursor: not-allowed !important;
}
.field-disabled {
  background-color: #eee !important;
  border: 1px solid #ddd !important;
  cursor: default;
}

.dark-text > div {
  color: #343a40 !important;
}
//for aligning delete icon
.delete-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}

.dark-text > div {
  color : #333 !important;
}

a {
  transition: all .3s ease-in-out;
  &:hover {
    color: #0056b3
  }
}
.custom-slide-toggle.mat-mdc-slide-toggle {
  .mdc-switch:enabled .mdc-switch__track::after {
    background: rgba(63,81,181,.54) !important;
  }
  .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
    background: #0066cc !important;
  }
}

.custom-text-editor .ql-snow .ql-tooltip {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}

.mat-step-header {
  pointer-events: none;
}

.mat-mdc-form-field-subscript-wrapper  {
  display: none;
}

.mat-horizontal-stepper-wrapper {
  .mat-step-header {
    padding: 0;
  }
  .mat-ripple,  .mat-step-label {
    display: none;
  }
}

.mat-mdc-tab-header {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.multi-select {
  .multiselect-dropdown .dropdown-btn {
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
    span {
      font-size: 14px;
      color: var(--app-heading-color) !important;
      font-weight: 500;
    }
    .dropdown-multiselect__caret:before {
      border-width: 9px 5px 0 5px !important;
      border-color: #000 transparent !important;
    }
    .selected-item {
      max-width: unset !important;
      span {
        font-size: 1rem;
        color: #fff !important;
        font-weight: 400;
      }
    }
  }
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #495057 !important;
}
.input-align {
  input {
    color: rgba(0, 0, 0, 0.87) !important;
    margin: 0 15px;
    opacity: 0.7;
  }
}
// floating label alignment
.filter-date-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex
{
  .mat-mdc-floating-label.mdc-floating-label--float-above{
    top: 32px;
  }
  input {
    margin-top: 10px;
  }
}
.mat-mdc-tab-body:has(.sub-project) {
  overflow-y: hidden !important;
}
.filter-area input::placeholder, .filter-area *::placeholder {
  color: #333 !important;
  opacity: 0.5 !important;
}
// .cdk-overlay-container {
//   .mat-mdc-tab-body-wrapper {
//     height: 430px;
//   }
//   // .mat-mdc-dialog-surface.mdc-dialog__surface {
//   //   overflow-y: hidden !important;
//   // }
//   }


//for header alignment for PM and FM role

#wrapper.project-manager:has(.alert-cron-msg), #wrapper.fund-manager:has(.alert-cron-msg) {
  .navbar {
    transition: all .3s ease-in-out;
    top: var(--alert-default-height);
    // top: var(--cron-header-dynamic-height);
  }
  .sidebar, .filter-area {
    top: calc(77px + var(--alert-default-height));
    // top: calc(71px + var(--cron-header-dynamic-height));
  }
  .main-content {
    margin-top: calc(77px + var(--alert-default-height));
    // margin-top: calc(71px + var(--cron-header-dynamic-height));
  }
}

.assignee-menu {
  > span {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .img-wrapper {
    width: 25px;
    height: 25px;

    img {
      width: 100%;
    }
  }
}

.custom-paginator {
  .mat-mdc-form-field {
    max-width: 100px;
  }
  .mat-mdc-select {
    border: none !important;
  }
}
