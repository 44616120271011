html {
    height: 100%;
}

body {
    height: 100%;
    font: 400 var(--app-default-size) var(--app-font-primary);
    line-height: var(--app-line-height);
    color: var(--app-default-color);
    background: #f0f0f0;
    -webkit-font-smoothing: antialiased;
}
// headings

h1,h2,h3,h4,h5,h6 {
    font-weight: 600;
    line-height: var(--app-heading-line-height);
    color: var(--app-heading-color);
}

h1 {
    font-size: var(--app-h1-size);
    margin: 0 0 1.5rem;
}

h2 {
    font-size: var(--app-h2-size);
    margin: 0 0 1.5rem;
}

h3 {
    font-size: var(--app-h3-size);
    margin: 5px 0 15px;
}

h4 {
    font-size: var(--app-h4-size);
}

h5 {
    font-size: var(--app-h5-size);
}

h6 {
    font-size: var(--app-h6-size);
}

.font-weight-medium {
    font-weight: 500;
}

.font-weight-semibold {
    font-weight: 600;
}

a {
    color: var(--app-primary-color);
    &:hover {
        text-decoration: none;
    }
}

/*==== image css ===*/

.o-cover {
    object-fit: cover !important;
}

.valign-middle {
    vertical-align: middle;
}
  

/*==== color css ====*/

.text-blue {
    color: var(--app-info-color);
}

.text-primary {
    color: var(--app-primary-color) !important;
}

.fill-primary {
    fill: var(--app-primary-color);
}

.stroke-primary {
    stroke: var(--app-primary-color);
}


.text-gray {
    color:  var(--app-gray-dark) !important;
}

.border-light-bottom {
    border-bottom: 1px solid rgba(255,255,255,0.5);
}